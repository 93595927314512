<template>
  <v-container fluid>
    <v-subheader>LEGO</v-subheader>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              ID
            </th>

            <th class="text-left">
              Név
            </th>

            <th class="text-left">
              Link
            </th>

            <th class="text-left">
              Legalacsonyabb ár
            </th>

            <th class="text-left">
              Utolsó módosítás
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="set in sets"
            :key="set._id"
          >
            <td>{{ set._id }}</td>
            <td>
              <router-link :to="{ name: 'lego-set', params: { id: set._id } }">
                {{ set.name }}
              </router-link>
            </td>
            <td><a :href="set.url" target="_blank">Link</a></td>
            <td>{{ set.lowest }}</td>
            <td>{{ set.updated }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      sets: [],
    };
  },

  methods: {
    async load() {
      const a = axios.create({
        withCredentials: true,
        baseURL: process.env.VUE_APP_BACKEND_HOST,
      });
      this.sets = await a.get('/lego').then((res) => res.data);
    },
  },

  mounted() {
    this.load();
  },
};
</script>
